<template>
	<div>
		<div class="card">
			<div class="card-body">
				<div class="row">
					<div class="d-flex justify-content-between flex-column col-xl-6 col-21">
						<div class="d-flex justify-content-start">
							<span class="b-avatar badge-light-primary rounded" style="width: 104px; height: 104px"><span
									class="b-avatar-img">
									<b-img :src="require('@/assets/images/svg/hatchery/farm.svg')" class="svg-img" height="30px"
										width="30px" />
								</span>
							</span>
							<div class="d-flex flex-column justify-content-center ml-1">
								<div class="mb-1">
									<h4 class="mb-0">{{ farm_details.name }}</h4>
								</div>
							</div>
						</div>
					</div>
					<div class="col-xl-6 col-12">
						<table class="mt-2 mt-xl-0 w-100">
							<tr>
								<th class="pb-50">
									<span class="font-weight-bold">Shed Count</span>
								</th>
								<td class="pb-50">{{ farm_details.shedCount }}</td>
							</tr>
							<tr>
								<th class="pb-50">
									<span class="font-weight-bold">Status</span>
								</th>
								<td class="pb-50 text-capitalize">{{ farm_details.status }}</td>
							</tr>
							<tr>
								<th class="pb-50">
									<span class="font-weight-bold">Address</span>
								</th>
								<td class="pb-50 text-capitalize">
									{{ farm_details.address }}
								</td>
							</tr>
							<tr>
								<th class="pb-50">
									<span class="font-weight-bold">Country</span>
								</th>
								<td class="pb-50">{{ farm_details.country.name }}</td>
							</tr>
							<tr>
								<th class="pb-50">
									<span class="font-weight-bold">State</span>
								</th>
								<td class="pb-50">{{ farm_details.state.name }}</td>
							</tr>
						</table>
					</div>
				</div>
			</div>
		</div>
		<!-- Table Container Card -->
		<b-card>
			<div class="m-2">
				<!-- Table Top -->
				<b-row class="justify-content-end">
					<!-- Date picker -->
					<!-- <b-col cols="12" md="7">
          <b-row>
            <b-col md="4 p-0 mr-1">
              <label for="example-input">From Date</label>
              <b-form-datepicker
                id="from-date"
                :date-format-options="{
                  year: 'numeric',
                  month: 'short',
                  day: '2-digit',
                  weekday: 'short',
                }"
                placeholder="Choose a date"
                local="en"
                v-model="search_filter.from_date"
              />
            </b-col>
            <b-col md="4 p-0">
              <label for="example-input">To Date</label>
              <b-form-datepicker
                id="to-date"
                :date-format-options="{
                  year: 'numeric',
                  month: 'short',
                  day: '2-digit',
                  weekday: 'short',
                }"
                placeholder="Choose a date"
                local="en"
                v-model="search_filter.to_date"
              />
            </b-col>
            <b-col md="2 d-flex align-items-end">
              <b-button @click="getFarmDetails()" variant="primary ">
                Filter
              </b-button>
            </b-col>
          </b-row>
        </b-col> -->
					<!-- Search -->
					<b-col cols="12" md="5" class="mt-2 d-flex justify-content-end align-items-center">
						<!-- <div>
            <b-form-input
              type="text"
              class="form-control"
              placeholder="Search"
              @input="getFarmDetails()"
              v-model.trim="search_filter.input_field"
              style="border-radius: 4px"
            />
          </div>
          <b-button
            @click="clearFilter"
            variant="secondary
 			 ml-1"
          >
            Clear
          </b-button> -->
						<b-button v-b-modal.add-shed variant="primary ">
							<feather-icon icon="PlusIcon" size="16" class="mr-1" />
							Add Shed
						</b-button>
						<!-- <b-button @click="excelDownload()" variant="warning">
            <feather-icon icon="DownloadIcon" size="16" />
          </b-button> -->
					</b-col>
				</b-row>
			</div>
			<div v-if="shed_list && shed_list.total > 0">
				<b-table id="shedTable" :items="shed_list.data" responsive :fields="fields"
					empty-text="No matching records found" class="position-relative">
					<template #cell(index)="data">
						<div class="ml-1">
							{{ shed_list.from + data.index }}
						</div>
					</template>
					<template #cell(status)="data">
						<div v-if="data.value === 'Active'" class="activeClass">Active</div>
						<div v-else class="inactiveClass">Inactive</div>
					</template>
					<!-- Column: Actions -->
					<template #cell(actions)="data">
						<div class="text-nowrap">
							<feather-icon icon="EditIcon" size="16" :id="`${data.item.id}-edit-icon`" v-b-modal.edit-shed
								v-b-tooltip.hover.left="{ variant: 'info' }" title="Edit Shed !"
								@click="() => getShedId(data.item.id)" />

							<b-tooltip :target="`${data.item.id}-edit-icon`" />

							<feather-icon @click="() => getRoles(data.item.id)" icon="PlusIcon" size="16"
								:id="`${data.item.id}-preview-icon`" class="mx-1 apply-icon" v-b-tooltip.hover.top="{ variant: 'info' }"
								title="Assign Users !" />

							<b-tooltip :target="`${data.item.id}-preview-icon`" />
							<router-link class="text-secondary" :to="{ path: `/apps/farm/${farmId}/shed/${data.item.id}/view` }">
								<feather-icon icon="EyeIcon" size="16" v-b-tooltip.hover.right="{ variant: 'info' }"
									title="View Shed!" />
							</router-link>
						</div>
					</template>
				</b-table>

				<div class="mt-2 mb-5">
					<b-pagination :value="shed_list.current_page" :total-rows="shed_list.total" :per-page="shed_list.per_page"
						align="right" @change="getFarmDetails">
					</b-pagination>
				</div>
			</div>
			<div v-else class="d-flex flex-column align-items-center py-5">
				<b-img :src="require('@/assets/images/svg/infographics/no_data_infographic.svg')
					" class="mr-2" />
				<h4 class="mt-2 Secondary">No shed added yet</h4>
			</div>
		</b-card>

		<!-- add Shed modal -->
		<b-modal size="lg" no-stacking id="add-shed" title="Add Shed" ok-only ok-title="Submit" cancel-title="Close"
			@ok="addShed" cancel-variant="outline-primary" ref="my-modal" @hidden="resetModal" :no-close-on-backdrop="true">
			<validation-observer ref="simpleRules">
				<b-form @submit="addShed" method="post">
					<b-form-group>
						<template v-slot:label>
							Enter Shed Name <span class="text-danger">*</span>
						</template>
						<div class="form-label-group">
							<validation-provider #default="{ errors }" name="Shed Name" rules="required|regex:^[A-Za-z0-9 ]+$">
								<b-form-input v-model="formData.shed_name" name="shedName" placeholder="Enter Shed Name"
									maxlength="30" />
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</div>
					</b-form-group>
				</b-form>
				<b-form-group>
					<template v-slot:label>
						Enter Shed Code <span class="text-danger">*</span>
					</template>
					<div class="form-label-group">
						<validation-provider #default="{ errors }" name="Shed Code" rules="required|regex:^[a-zA-Z0-9._-]+$">
							<b-form-input v-model="formData.shed_code" name="shedCode" placeholder="Enter Shed Code" maxlength="30" />
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</div>
				</b-form-group>
				<b-form-group>
					<template v-slot:label>
						Production Type<span class="text-danger">*</span>
					</template>
					<div class="form-label-group">
						<validation-provider #default="{ errors }" name=" Production Type" rules="required">
							<v-select v-model="formData.production_type_id" :options="production_type" label="name" clearable:false
								:reduce="(e) => e.id" @input="getBreedType" />
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</div>
				</b-form-group>
				<b-form-group>
					<template v-slot:label>
						Breed Type<span class="text-danger">*</span>
					</template>
					<div class="form-label-group">
						<validation-provider #default="{ errors }" name=" Breed Type" rules="required">
							<v-select v-model="formData.breed_id" :options="breeds" label="name" clearable:false
								:reduce="(e) => e.id" />
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</div>
				</b-form-group>
				<b-form-group>
					<template v-slot:label>
						Breed Stages<span class="text-danger">*</span>
					</template>
					<div class="form-label-group">
						<validation-provider #default="{ errors }" name=" Breed Stage" rules="required">
							<v-select v-model="formData.breed_stage_id" :options="stages" label="name" clearable:false
								:reduce="(e) => e.id" />
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</div>
				</b-form-group>
			</validation-observer>
			<template #modal-footer="">
				<!-- <b-button
          size="md"
          v-b-modal.modal-multi-2
          variant="success"
          @click="ok()"
        >
          Assign Users
        </b-button> -->
				<b-button size="md" variant="primary" @click="addShed()">
					Submit
				</b-button>
			</template>
		</b-modal>
		<!-- Edit Shed modal -->
		<b-modal id="edit-shed" title="Edit Shed" ok-only @ok="updateShed" ok-title="Submit" cancel-title="Close"
			cancel-variant="outline-primary" ref="my-modal">
			<b-form method="post">
				<b-form-group label="Enter Shed Name">
					<div class="form-label-group">
						<b-form-input v-model="formData.shed_name" name="shed name" placeholder="Enter Shed Name" maxlength="30"
							rules="required|regex:^[A-Za-z0-9 ]+$" />
					</div>
				</b-form-group>
				<b-form-group label="Enter Shed Code">
					<div class="form-label-group">
						<b-form-input v-model="formData.shed_code" name="shed code" placeholder="Enter Shed Code" maxlength="30"
							disabled rules="required|regex:^[A-Za-z0-9 ]+$" />
					</div>
				</b-form-group>
				<b-form-group label="Status" label-for=" status">
					<div class="d-flex mt-1">
						<b-form-radio v-model="formData.status" name="status" value="Active" class="mr-1">Active</b-form-radio>
						<b-form-radio v-model="formData.status" name="status" value="InActive">InActive</b-form-radio>
					</div>
				</b-form-group>
			</b-form>
		</b-modal>
		<!-- delete shed modal -->

		<b-modal id="modal-multi-2" size="lg" title="Assign User" variant="primary" ok-title="Assign" @ok="assignUsers"
			v-model="show">
			<tab-content title="Assign User" icon="feather icon-map-pin">
				<div class="assignedUser">
					<b-row v-for="(item, index) in assigned_user" :id="item.id" :key="index" ref="row">
						<b-col md="4" class="custom ml-1 mb-2">
							<b-form-input :value="item.roles[0].name" id="role" disabled="disabled" name="role" md="6" />
						</b-col>
						<b-col md="4">
							<b-form-input :value="item.user_name" id="user" name="user" disabled="disabled" />
						</b-col>
						<b-col lg="2" md="4" class="mb-50">
							<b-button variant="outline-danger" class="delete-button"
								@click="removeAssigned(index, item.roles[0].id, item.id)">
								<feather-icon icon="XIcon" class="mr-25" />
								<span>Delete</span>
							</b-button>
						</b-col>
						<b-col cols="12">
							<hr />
						</b-col>
					</b-row>
				</div>
				<div class="ml-1 mb-2">
					<b-row v-for="(item, index) in userData.assign_user" :id="item.id" :key="index" ref="row">
						<!-- Roles -->
						<b-col md="4">
							<b-form-group label="Roles" label-for="Roles">
								<v-select label="selected_role" :id="`selectRole_${index}`" v-model="userData.assign_user[index].role"
									:reduce="(e) => e.slug" :options="roles" :getOptionLabel="roleLabel" :searchable="true"
									@input="getSelectedRolesName($event, index)" :clearable="false"></v-select>
							</b-form-group>
						</b-col>

						<!-- User select -->

						<b-col md="4">
							<b-form-group label="Users" label-for="Users">
								<v-select label="selected_user" :id="`selectUser_${index}`" v-model="userData.assign_user[index].user"
									:reduce="(e) => e.id" :options="userList[index]" :getOptionLabel="userLabel" :searchable="true"
									:clearable="false"></v-select>
							</b-form-group>
						</b-col>

						<!-- Remove Button -->
						<b-col md="4" class="mb-50">
							<b-button v-if="userData.assign_user.length > 1" variant="outline-danger" class="mt-0 mt-md-2"
								@click="removeItem(index)">
								<feather-icon icon="XIcon" class="mr-25" />
								<span>Delete</span>
							</b-button>
						</b-col>
						<b-col cols="12">
							<hr />
						</b-col>
					</b-row>
				</div>
				<b-button variant="primary" @click="repeatAgain()">
					<feather-icon icon="PlusIcon" class="mr-25" />
					<span>Add New</span>
				</b-button>
			</tab-content>
		</b-modal>
		<DeleteModal id="modal-shed" :onClicked="deleteShed" />
	</div>
</template>

<script>
import vSelect from "vue-select";
import { BASE_URL } from "@core/common/constants";
import axiosIns from "@/libs/axios";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import DeleteModal from "@core/components/modal/Delete-modal.vue";
import { required, alpha } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { url } from "@/@core/utils/validations/validations";
export default {
	components: {
		vSelect,
		DeleteModal
	},
	data() {
		return {
			alpha,
			required,
			sortDesc: null,
			selected: null,
			search: null,
			formData: {
				shed_name: null,
				shed_code: null,
				production_type: {},
				status: null,
				breed_id: null,
				breed_stage_id: null
			},
			breeds: [],
			stages: [],
			show: false,
			search_filter: {
				input_field: "",
				to_date: "",
				from_date: ""
			},
			companyRoles: [],
			userList: [],
			state_name: null,
			shedData: {
				shed_name: null,
				pin_code: null,
				address: null,
				selectedState: null,
				selectedDistrict: null,
				selectedCity: null
			},
			userData: {
				assign_user: [
					{
						role: null,
						user: null
					}
				]
			},
			assigned_user: [],
			roles: [],
			fields: [
				{ key: "index", label: "No" },
				"shed_name",
				"shed_code",
				{ key: "production_type.name", label: "production Type" },
				{ key: "breed.name", label: "Breed" },
				{ key: "breed_stage.name", label: "Breed Stage" },
				{
					key: "created_at",
					label: "created at",
					sortable: true,
					formatter: (value) => {
						return moment(value).format("MMMM Do YYYY");
					}
				},
				{ key: "status", label: "status" },

				"Actions"
			],
			shedColumns: {},
			filterData: [],
			option: null,
			shedName: null,
			sourceList: null,
			page: null,
			companyId: null,
			farmId: null,
			farm_details: [],
			shed_list: [],
			production_type: [],
			shedId: null
		};
	},
	created: function () {
		this.getFarmDetails();
		this.companyId = this.$route.params.companyId;
		this.farmId = this.$route.params.farmId;
		this.shedId = this.$route.params.shedId;
	},

	computed: {
		rows() {
			return this.shedColumns.length;
		}
	},
	methods: {
		moment() {
			return moment();
		},
		resetModal() {
			this.formData.shed_name = "";
			this.formData.production_type_id = "";
		},
		roleLabel(option) {
			return option.name;
		},
		userLabel(option) {
			return option.user_name;
		},
		repeatAgain() {
			let newRole = {
				role: null,
				user: null
			};
			this.userData.assign_user.push(newRole);
			// this.$nextTick(() => {
			// 	this.trAddHeight(this.$refs.row[0].offsetHeight);
			// });
		},
		removeItem(index) {
			this.userData.assign_user.splice(index, 1);
			// this.trTrimHeight(this.$refs.row[0].offsetHeight);
		},
		initTrHeight() {
			this.trSetHeight(null);
		},
		getFarmDetails(pageNo = 1) {
			let farmId = this.$route.params.farmId;
			let companyId = this.companyId;
			let url = "";
			if (companyId) {
				url = `web/company/${companyId}/farm/${farmId}/view`;
			} else {
				url = `web/farm/${farmId}/view`;
			}
			let queryParams = {
				page: pageNo
			};

			if (this.search_filter.input_field) {
				queryParams.input_field = this.search_filter.input_field;
			}
			if (this.search_filter.to_date) {
				queryParams.to_date = this.search_filter.to_date;
			}
			if (this.search_filter.from_date) {
				queryParams.from_date = this.search_filter.from_date;
			}
			axiosIns
				.get(this.addQueryString(url, queryParams))
				.then((response) => {
					this.farm_details = response.data.farm_details;
					this.shed_list = response.data.shed_list;
					this.state_name = response.data.farm_details.state.state_name;
					this.production_type = response.data.production_type;
				})
				.catch((error) => {
					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "danger",
							text: data
						}
					});
				});
		},
		addShed(e) {
			// e.preventDefault();
			this.$refs.simpleRules.validate().then((success) => {
				if (success) {
					var formData = new FormData();
					formData.append("farm_id", this.farmId);
					let form_data = this.objectToFormData(this.formData, formData);
					axiosIns
						.post(`web/sheds`, form_data)
						.then((res) => {
							this.$toast({
								component: ToastificationContent,
								position: "top-right",
								props: {
									icon: "CoffeeIcon",
									variant: "success",
									text: `Data Successfully Added `
								}
							});
							this.$refs["my-modal"].hide();
							this.clearForm();
							this.getFarmDetails();
						})
						.catch((error) => {
							const data = error.response.data.message;
							this.$toast({
								component: ToastificationContent,
								position: "top-right",
								props: {
									icon: "CoffeeIcon",
									variant: "danger",
									text: data
								}
							});
						});
				}
			});
		},
		getShedId(id) {
			axiosIns
				.get(`web/sheds/${id}`)
				.then((response) => {
					this.formData = response.data.shed_info;
					this.id = id;
				})
				.catch((error) => {
					const data = error.response.data.message;
					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "danger",
							text: data
						}
					});
				});
		},
		updateShed() {
			var formData = new FormData();
			formData.append("_method", "PUT");
			let form_data = this.objectToFormData(this.formData, formData);
			axiosIns
				.post(`web/sheds/${this.id}`, form_data)
				.then((response) => {
					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "success",
							text: `Data Updated Successfully `
						}
					});
					this.$refs["my-modal"].hide();
					this.getFarmDetails();
				})
				.catch((error) => {
					const data = error.response.data.message;
					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "danger",
							text: data
						}
					});
				});
		},
		filterName(event) {
			let filterText = event.target.value;
			var newArray = this.shedColumns.filter(function (el) {
				return el.name.toLowerCase().includes(filterText.toLowerCase());
			});
			// (newArray);
			this.filterData = newArray;
		},
		setShedId(id) {
			this.shedId = id;
		},
		deleteShed(id) {
			var data = new FormData();
			data.append("_method", "DELETE");
			axiosIns
				.post(`web/sheds/${this.id}`, data)
				.then((response) => {
					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "danger",
							text: `Data Successfully Deleted `
						}
					});
					this.$refs["my-modal"].hide();
					this.getFarmDetails();
				})
				.catch((error) => {
					const data = error.response.data.message;
					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "danger",
							text: data
						}
					});
				});
		},
		assignUsers() {
			let farmId = this.$route.params.farmId;
			let shedId = this.shedId;
			var formData = new FormData();
			let form_data = this.objectToFormData(this.userData, formData);
			axiosIns
				.post(`web/farm/${farmId}/shed/${shedId}/assign-users`, form_data)
				.then((res) => {
					const data = res.data.message;
					this.userData.assign_user = [
						{
							role: null,
							user: null
						}
					];
					// this.userData.assign_user[] = "";
					this.show = true;
					this.getAssignedUsers();
					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "success",
							text: data
						}
					});
				})
				.catch((error) => {
					this.userData.assign_user.role = "";
					this.userData.assign_user.name = "";
					this.show = true;
					this.getAssignedUsers();
					const data = error.response.data.message;
					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "danger",
							text: data
						}
					});
				});
		},
		excelDownload() {
			if (this.search_filter.from_date && this.search_filter.to_date) {
				this.search_filter.from_date = moment(
					this.search_filter.from_date
				).format("YYYY-MM-DD");
				this.search_filter.to_date = moment(this.search_filter.to_date).format(
					"YYYY-MM-DD"
				);
			}
			let order_filter_data = this.prepareForm(this.search_filter);
			axiosIns
				.post(`web/export-sheds`, order_filter_data)
				.then((response) => {
					var tempLink = document.createElement("a");
					tempLink.style.display = "none";
					tempLink.href = response.data.file;
					tempLink.setAttribute("download", response.data.name);
					if (typeof tempLink.download === "undefined") {
						tempLink.setAttribute("target", "_blank");
					}
					document.body.appendChild(tempLink);
					tempLink.click();
					document.body.removeChild(tempLink);
					this.getFarmDetails();
				})
				.catch((error) => {
					const data = error.response.data.message;
					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "danger",
							text: data
						}
					});
					this.search_filter.to_date = null;
					this.search_filter.from_date = null;
					this.search_filter.input_field == null;
					this.getFarmDetails();
				});
		},
		clearFilter() {
			this.search_filter.input_field = "";
			this.search_filter.to_date = "";
			this.search_filter.from_date = "";
			this.getFarmDetails();
		},
		clearForm() {
			this.formData.input_field = "";
			this.formData.to_date = "";
			this.formData.from_date = "";
			this.getFarmDetails();
		},
		getRoles(id) {
			this.setShedId(id);
			axiosIns
				.get(`web/getCompanyRoles`)
				.then((response) => {
					this.roles = response.data.companyRoles;
					this.getAssignedUsers();
					this.show = true;
				})
				.catch((error) => {
					const data = error.response.data.message;
					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "danger",
							text: data
						}
					});
				});
		},
		getSelectedRolesName(slug, index) {
			let companyId = this.companyId;
			let url = "";
			if (companyId) {
				url = `web/company/${companyId}/getUser/${slug}`;
			} else {
				url = `web/shed/${this.shedId}/getRoleUser/${slug}`;
			}
			axiosIns
				.get(url)
				.then((response) => {
					this.$set(this.userList, index, response.data.unAssignedUsers);
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		getAssignedUsers() {
			let url = `web/shed/${this.shedId}/get-assigned-users`;
			axiosIns
				.get(url)
				.then((response) => {
					this.assigned_user = response.data.assigned_users.assigned_users;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		removeAssigned(index, roleId, userId) {
			let url = `web/shed/${this.shedId}/remove-user/${userId}`;
			axiosIns
				.post(url)
				.then((res) => {
					this.getAssignedUsers();
					const data = res.data.message;
					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							// title: `Welcome `,
							icon: "CoffeeIcon",
							variant: "success",
							text: data
						}
					});
					// this.getDataHatchery();
				})
				.catch((error) => {
					const data = error.response.data.message;
					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							// title: `Welcome `,
							icon: "CoffeeIcon",
							variant: "danger",
							text: data
						}
					});
					// this.getDataHatchery();
				});
		},
		getBreedType(productionType) {
			axiosIns
				.get(`web/breeds/${productionType}`)
				.then((response) => {
					this.breeds = response.data.breeds;
					this.stages = response.data.stages;
				})
				.catch((error) => {
					const data = error.response.data.message;
					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "danger",
							text: data
						}
					});
				});
		}
	}
};
</script>

<style lang="scss" scoped>
.activeClass {
	background-color: #28c76f;
	font-size: 10px;
	color: #ffffff;
	display: flex;
	align-items: center;
	width: 50px;
	height: 20px;
	border-radius: 10px;
	justify-content: center;
}

.inactiveClass {
	background-color: #ea5455;
	font-size: 10px;
	color: #fff;
	display: flex;
	align-items: center;
	width: 50px;
	height: 20px;
	justify-content: center;
	border-radius: 10px;
}

.apply-icon {
	cursor: pointer;
}

.delete-button {
	width: 10rem;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
